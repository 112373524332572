import React from 'react'

import PropTypes from 'prop-types'

import './branding.css'

const Branding = (props) => {
  return (
    <div className="branding-branding">
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="branding-image"
      />
    </div>
  )
}

Branding.defaultProps = {
  imageSrc: '/cold%20hooks%20logo%20black%20360x100-200h.webp',
  imageAlt: 'image',
}

Branding.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default Branding
