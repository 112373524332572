import React from 'react'

import './content-list11.css'

const ContentList11 = (props) => {
  return (
    <div className="content-list11-container thq-section-padding">
      <div className="content-list11-max-width thq-section-max-width">
        <div className="content-list11-content thq-flex-column">
          <span>
            <span>Last Updated: 06-30-2024</span>
            <br></br>
            <br></br>
            <span className="content-list11-text004">Terms and Conditions</span>
            <br></br>
            <br></br>
            <span>Agreement between User and https://www.coldhooks.com/</span>
            <br></br>
            <br></br>
            <span>
              Welcome to https://www.coldhooks.com/. The
              https://www.coldhooks.com/ website (the “Site”) is comprised of
              various web pages operated by Algorythum International Pvt. Ltd.
              https://www.ColdHooks.com/ is offered to you conditioned on your
              acceptance without modification of the terms, conditions, and
              notices contained herein (the “Terms”). Your use of
              https://www.coldhooks.com/ constitutes your agreement to all such
              Terms. Please read these terms carefully, and keep a copy of them
              for your reference.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>https://www.coldhooks.com/ is a Saas &amp; Blog Site.</span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text020">
              Electronic Communications
            </span>
            <br></br>
            <br></br>
            <span>
              Visiting https://www.coldhooks.com/ or sending emails to ColdHooks
              constitutes electronic communications. You consent to receive
              electronic communications and you agree that all agreements,
              notices, disclosures and other communications that we provide to
              you electronically, via email and on the Site, satisfy any legal
              requirement that such communications be in writing.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text028">
              Children Under Thirteen
            </span>
            <br></br>
            <br></br>
            <span>
              ColdHooks does not knowingly collect, either online or offline,
              personal information from persons under the age of thirteen. If
              you are under 18, you may use https://www.coldhooks.com/ only with
              permission of a parent or guardian.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text036">
              Links to Third Party Sites/Third Party Services
            </span>
            <br></br>
            <br></br>
            <span>
              https://www.coldhooks.com/ may contain links to other websites
              (“Linked Sites”). The Linked Sites are not under the control of
              ColdHooks, and ColdHooks is not responsible for the contents of
              any Linked Site, including without limitation any link contained
              in a Linked Site, or any changes or updates to a Linked Site.
              ColdHooks is providing these links to you only as a convenience,
              and the inclusion of any link does not imply endorsement by
              ColdHooks of the site or any association with its operators.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              Certain services made available via https://www.coldhooks.com/ are
              delivered by third party sites and organizations. By using any
              product, service or functionality originating from the
              https://www.coldhooks.com/ domain, you hereby acknowledge and
              consent that ColdHooks may share such information and data with
              any third party with whom ColdHooks has a contractual relationship
              to provide the requested product, service or functionality on
              behalf of https://www.coldhooks.com/ users and customers.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text049">
              No Unlawful or Prohibited Use/Intellectual Property
            </span>
            <br></br>
            <br></br>
            <span>
              You are granted a non-exclusive, non-transferable, revocable
              license to access and use https://www.ColdHooks.com/ strictly in
              accordance with these terms of use. As a condition of your use of
              the Site, you warrant to ColdHooks that you will not use the Site
              for any purpose that is unlawful or prohibited by these Terms. You
              may not use the Site in any manner which could damage, disable,
              overburden, or impair the Site or interfere with any other party’s
              use and enjoyment of the Site. You may not obtain or attempt to
              obtain any materials or information through any means not
              intentionally made available or provided for through the Site.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              All content included as part of the Service, such as text,
              graphics, logos, images, as well as the compilation thereof, and
              any software used on the Site, is the property of Algorythum
              International Pvt. Ltd. or its suppliers and protected by
              copyright and other laws that protect intellectual property and
              proprietary rights. You agree to observe and abide by all
              copyright and other proprietary notices, legends or other
              restrictions contained in any such content and will not make any
              changes thereto.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              You will not modify, publish, transmit, reverse engineer,
              participate in the transfer or sale, create derivative works, or
              in any way exploit any of the content, in whole or in part, found
              on the Site. ColdHooks content is not for resale. Your use of the
              Site does not entitle you to make any unauthorized use of any
              protected content, and in particular you will not delete or alter
              any proprietary rights or attribution notices in any content. You
              will use protected content solely for your personal use, and will
              make no other use of the content without the express written
              permission of ColdHooks and the copyright owner. You agree that
              you do not acquire any ownership rights in any protected content.
              We do not grant you any licenses, express or implied, to the
              intellectual property of Algorythum International Pvt. Ltd. or our
              licensors except as expressly authorized by these Terms.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text067">International Users</span>
            <br></br>
            <br></br>
            <span>
              The Service is controlled, operated and administered by Algorythum
              International Pvt. Ltd. from our offices within India. If you
              access the Service from a location outside India, you are
              responsible for compliance with all local laws. You agree that you
              will not use the ColdHooks Content accessed through
              https://www.ColdHooks.com/ in any country or in any manner
              prohibited by any applicable laws, restrictions or regulations.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text075">Indemnification</span>
            <br></br>
            <br></br>
            <span>
              You agree to indemnify, defend and hold harmless Algorythum
              International Pvt. Ltd., its officers, directors, employees,
              agents and third parties, for any losses, costs, liabilities and
              expenses (including reasonable attorney’s fees) relating to or
              arising out of your use of or inability to use the Site or
              services, any user postings made by you, your violation of any
              terms of this Agreement or your violation of any rights of a third
              party, or your violation of any applicable laws, rules or
              regulations. Algorythum International Pvt. Ltd. reserves the
              right, at its own cost, to assume the exclusive defense and
              control of any matter otherwise subject to indemnification by you,
              in which event you will fully cooperate with Algorythum
              International Pvt. Ltd. in asserting any available defenses.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text083">Class Action Waiver</span>
            <br></br>
            <br></br>
            <span>
              Any arbitration under these Terms and Conditions will take place
              on an individual basis; class arbitrations and
              class/representative/collective actions are not permitted. THE
              PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
              IN EACH’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
              PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL
              ACTION AGAINST THE OTHER. Further, unless both you and Algorythum
              International Pvt. Ltd. agree otherwise, the arbitrator may not
              consolidate more than one person’s claims, and may not otherwise
              preside over any form of a representative or class proceeding.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text091">Liability Disclaimer</span>
            <br></br>
            <br></br>
            <span>
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
              AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
              TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
              INFORMATION HEREIN. COLDHOOKS AND/OR ITS SUPPLIERS MAY MAKE
              IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              COLDHOOKS AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
              SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
              OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
              GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED “AS
              IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. COLDHOOKS AND/OR
              ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
              REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
              RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
              NON-INFRINGEMENT.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL ALGORYTHUM INTERNATIONAL PVT. LTD. AND/OR ITS SUPPLIERS BE
              LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
              LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT
              OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE
              SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED
              SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR
              ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
              OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
              THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY OR OTHERWISE, EVEN IF COLDHOOKS OR ANY OF ITS SUPPLIERS
              HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
              STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
              LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
              PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
              AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text109">
              Termination/Access Restriction
            </span>
            <br></br>
            <br></br>
            <span>
              Algorythum International Pvt. Ltd. reserves the right, in its sole
              discretion, to terminate your access to the Site and the related
              services or any portion thereof at any time, without notice. To
              the maximum extent permitted by law, this agreement is governed by
              the laws of India and you hereby consent to the exclusive
              jurisdiction and venue of courts in India in all disputes arising
              out of or relating to the use of the Site. Use of the Site is
              unauthorized in any jurisdiction that does not give effect to all
              provisions of these Terms, including, without limitation, this
              section.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and Algorythum
              International Pvt. Ltd. as a result of this agreement or use of
              the Site. ColdHooks’ performance of this agreement is subject to
              existing laws and legal process, and nothing contained in this
              agreement is in derogation of Algorythum International Pvt. Ltd.’s
              right to comply with governmental, court and law enforcement
              requests or requirements relating to your use of the Site or
              information provided to or gathered by ColdHooks with respect to
              such use. If any part of this agreement is determined to be
              invalid or unenforceable pursuant to applicable law including, but
              not limited to, the warranty disclaimers and liability limitations
              set forth above, then the invalid or unenforceable provision will
              be deemed superseded by a valid, enforceable provision that most
              closely matches the intent of the original provision and the
              remainder of the agreement shall continue in effect.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              Unless otherwise specified herein, this agreement constitutes the
              entire agreement between the user and Algorythum International
              Pvt. Ltd. with respect to the Site and it supersedes all prior or
              contemporaneous communications and proposals, whether electronic,
              oral or written, between the user and ColdHooks with respect to
              the Site. A printed version of this agreement and of any notice
              given in electronic form shall be admissible in judicial or
              administrative proceedings based upon or relating to this
              agreement to the same extent and subject to the same conditions as
              other business documents and records originally generated and
              maintained in printed form. It is the express wish to the parties
              that this agreement and all related documents be written in
              English.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text127">
              Refunds and Cancellation Policy
            </span>
            <br></br>
            <br></br>
            <span>No Refunds</span>
            <br></br>
            <br></br>
            <span>
              Due to the digital nature of our service, all fees associated with
              your usage are non-refundable. This includes monthly or annual
              subscription charges, credits and any additional service fees
              incurred during your usage term. In case of technical failures,
              please contact us and we will add the corresponding credits in 2-7
              working days subject to internal approvals.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>No Cancellations</span>
            <br></br>
            <br></br>
            <span>
              Subscriptions and usage will continue for the entire billing
              period they are purchased for (monthly or annually). We do not
              offer prorated refunds for partial months of service, or unused
              credits.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span>Cancellations for Future Billing Periods</span>
            <br></br>
            <br></br>
            <span>
              You can cancel your subscription at any time to prevent automatic
              renewal for the next billing period. However, you will not be
              refunded for the current billing period, even if you cancel before
              the end of the month or year.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span>Service Availability</span>
            <br></br>
            <br></br>
            <span>
              We strive to provide our service with high uptime and
              availability. However, we cannot guarantee uninterrupted service
              and will not offer refunds for service interruptions.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>Free Trial Period</span>
            <br></br>
            <br></br>
            <span>
              We offer a free trial period with 50 free credits. In case of a
              subscription, once the free credits are used, your subscription
              will automatically convert to a paid plan according to the terms
              you selected, and your chosen payment method will be charged. If
              no subscription is selected, credits are required to continue to
              use the service.
            </span>
            <br></br>
            <br></br>
            <span>
              By logging in to our app, you acknowledge that you have read and
              agree to the Refunds and Cancellations Policy.
            </span>
            <br></br>
            <br></br>
            <span>
              We recommend trying our free trial to ensure our service meets
              your needs before subscribing.
            </span>
            <br></br>
            <br></br>
            <span>
              For any questions or special requests, please contact our support
              team.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text176">Changes to Terms</span>
            <br></br>
            <br></br>
            <span>
              Algorythum International Pvt. Ltd. reserves the right, in its sole
              discretion, to change the Terms under which
              https://www.ColdHooks.com/ is offered. The most current version of
              the Terms will supersede all previous versions. ColdHooks
              encourages you to periodically review the Terms to stay informed
              of our updates.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list11-text183">Contact Us</span>
            <br></br>
            <br></br>
            <span>
              ColdHooks welcomes your questions or comments regarding the Terms:
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>
              ColdHooks, a product of Algorythum International Pvt. Ltd.
            </span>
            <br></br>
            <br></br>
            <span>Hyderabad, India.</span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>Email Address:</span>
            <br></br>
            <br></br>
            <span>contact[at]coldhooks.com</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ContentList11
