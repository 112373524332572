import React from 'react'

import './content-list1.css'

const ContentList1 = (props) => {
  return (
    <div className="content-list1-container thq-section-padding">
      <div className="content-list1-max-width thq-section-max-width">
        <div className="content-list1-content thq-flex-column">
          <span>
            <span className="content-list1-text001">
              Last Updated: June 30, 2024
            </span>
            <br></br>
            <br></br>
            <span>
              Welcome to ColdHooks. This Privacy Policy outlines how Algorythum
              International Pvt. Ltd. owned ColdHooks, operating through the
              website [https://coldhooks.com] (referred to as “we,” “us,” or
              “our”), collects, uses, and protects your information. Your
              privacy is important to us, and we are committed to ensuring the
              security and confidentiality of the information you provide.
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span className="content-list1-text009">Website Visitors</span>
            <br></br>
            <br></br>
            <span>
              Similar to most websites, we collect non-personally-identifying
              information, such as browser type, device type, language
              preference, referring site, and the date and time of each visitor
              request. This information helps us better understand how visitors
              interact with our website. We also collect potentially
              personally-identifying information, such as Internet Protocol (IP)
              addresses, but we do not use this information to identify
              visitors, and we do not disclose it unless required by law.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text018">
              Collection of Personally-Identifying Information
            </span>
            <br></br>
            <br></br>
            <span>
              When providing our services, we may request personal information
              such as your name, email address, date of birth, phone number, and
              payment information. This information is collected only when
              relevant to our services and with your informed consent.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text027">
              Protection of Personal Information
            </span>
            <br></br>
            <br></br>
            <span>
              We disclose potentially personally-identifying information only to
              employees, contractors, and affiliated organizations who need it
              to provide services on our behalf. We do not rent or sell this
              information to third parties. By using our services, you consent
              to the transfer of your information to these parties, some of
              which may be located outside your home country.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text036">
              Use of Personal Information
            </span>
            <br></br>
            <br></br>
            <span>
              We will not sell or lease your personal information to third
              parties without your permission or unless required by law. If you
              have consented to marketing, you may opt out at any time by
              clicking the unsubscribe link in our emails.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text045">
              Access to Data and Retention
            </span>
            <br></br>
            <br></br>
            <span>
              We retain contact information until you unsubscribe or request
              deletion. If you wish to access or delete your personal
              information, please email us at contact[at]coldhooks.com.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text054">
              Rights in Relation to Your Information
            </span>
            <br></br>
            <br></br>
            <span>
              You may have rights under data protection law, including the right
              to request a copy of your information, update or correct it,
              request deletion, object to processing, or withdraw consent.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text063">Third-Party Links</span>
            <br></br>
            <br></br>
            <span>
              Our Website may contain links to third-party websites. We are not
              responsible for the data practices of these websites, and this
              Privacy Policy applies only to our Website and its subdomains.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text072">Aggregated Statistics</span>
            <br></br>
            <br></br>
            <span>
              We may collect statistics about visitor behavior but do not
              disclose personally-identifying information, except as outlined in
              this policy.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text081">Cookies</span>
            <br></br>
            <br></br>
            <span>
              We use cookies to collect information about your use of our
              Website. You can manage cookie preferences through your browser
              settings.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text090">
              Privacy Policy Changes
            </span>
            <br></br>
            <br></br>
            <span>
              We may update our Privacy Policy, and it is your responsibility to
              check for changes. Continued use of our Website after any
              modification constitutes acceptance of the revised Privacy Policy.
            </span>
            <br></br>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span className="content-list1-text099">Contact</span>
            <br></br>
            <br></br>
            <span>
              For questions about our privacy policy or your information, please
              contact us at contact[at]coldhooks.com.
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ContentList1
