import React from 'react'

import PropTypes from 'prop-types'

import './steps1.css'

const Steps1 = (props) => {
  return (
    <div className="steps1-container thq-section-padding">
      <h2 className="steps1-text">Get Hooked in 4 Easy Steps</h2>
      <div className="steps1-max-width thq-section-max-width">
        <div className="steps1-container1">
          <div className="steps1-container2 thq-card">
            <img
              alt={props.step1ImageAlt}
              src={props.step1ImageSrc}
              className="steps1-image thq-img-ratio-1-1"
            />
            <h2 className="steps1-text01 thq-heading-2">Upload</h2>
            <span className="steps1-text02 thq-body-small">
              Upload your prospect data (CSV).
            </span>
            <label className="steps1-text03 thq-heading-3">01</label>
          </div>
          <div className="steps1-container3 thq-card">
            <img
              alt={props.step2Alt}
              src={props.step2ImageSrc}
              className="steps1-image1 thq-img-ratio-1-1"
            />
            <h2 className="steps1-text04 thq-heading-2">Map</h2>
            <span className="steps1-text05 thq-body-small">
              Map your data fields to the variables inside AI prompts.
            </span>
            <label className="steps1-text06 thq-heading-3">02</label>
          </div>
        </div>
        <div className="steps1-container4">
          <div className="steps1-container5 thq-card">
            <img
              alt={props.step3ImageAlt}
              src={props.step3ImageSrc}
              className="steps1-image2 thq-img-ratio-1-1"
            />
            <h2 className="steps1-text07 thq-heading-2">Clean</h2>
            <span className="steps1-text08 thq-body-small">
              {' '}
              Choose various custom methods for multi-step bulk cleaning.
            </span>
            <label className="steps1-text09 thq-heading-3">03</label>
          </div>
          <div className="steps1-container6 thq-card">
            <img
              alt={props.step4ImageAlt}
              src={props.step4ImageSrc}
              className="steps1-image3 thq-img-ratio-1-1"
            />
            <h2 className="steps1-text10 thq-heading-2">Download</h2>
            <span className="steps1-text11 thq-body-small">
              Download the processed CSV with AI-personalized icebreakers.
            </span>
            <label className="steps1-text12 thq-heading-3">04</label>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps1.defaultProps = {
  step1ImageSrc: '/icon-upload.svg',
  step4ImageSrc: '/icon-download.svg',
  step2Alt: 'image',
  step3ImageSrc: '/icon-filter.svg',
  step1ImageAlt: 'image',
  step2ImageSrc: '/icon-map.svg',
  step4ImageAlt: 'image',
  step3ImageAlt: 'image',
}

Steps1.propTypes = {
  step1ImageSrc: PropTypes.string,
  step4ImageSrc: PropTypes.string,
  step2Alt: PropTypes.string,
  step3ImageSrc: PropTypes.string,
  step1ImageAlt: PropTypes.string,
  step2ImageSrc: PropTypes.string,
  step4ImageAlt: PropTypes.string,
  step3ImageAlt: PropTypes.string,
}

export default Steps1
