import React from 'react'

import PropTypes from 'prop-types'

import './pricing10.css'

const Pricing10 = (props) => {
  return (
    <div
      className={`pricing10-pricing23 thq-section-padding ${props.rootClassName} `}
    >
      <div className="pricing10-max-width thq-section-max-width">
        <div className="pricing10-content">
          <div className="pricing10-container"></div>
          <div className="pricing10-column1">
            <div className="pricing10-price">
              <div className="pricing10-price01">
                <p className="pricing10-text thq-body-large">Starter</p>
                <h3 className="pricing10-text01 thq-heading-3">$6</h3>
                <p className="pricing10-text02 thq-body-large">1K Credits</p>
              </div>
            </div>
            <a
              href="https://app.coldhooks.com"
              className="pricing10-button thq-button-animated thq-button-filled"
            >
              <span className="pricing10-text03 thq-body-small">
                Start Trial
              </span>
            </a>
          </div>
          <div className="pricing10-column2">
            <div className="pricing10-price02">
              <div className="pricing10-price03">
                <p className="pricing10-text04 thq-body-large">Essentials</p>
                <h3 className="pricing10-text05 thq-heading-3">$20</h3>
                <p className="pricing10-text06 thq-body-large">4K Credits</p>
              </div>
            </div>
            <a
              href="https://app.coldhooks.com"
              target="_blank"
              rel="noreferrer noopener"
              className="pricing10-button1 thq-button-animated thq-button-filled"
            >
              <span className="pricing10-text07 thq-body-small">
                Start Trial
              </span>
            </a>
          </div>
          <div className="pricing10-column3">
            <div className="pricing10-price04">
              <div className="pricing10-price05">
                <p className="pricing10-text08 thq-body-large">Scale-up</p>
                <h3 className="pricing10-text09 thq-heading-3">$36</h3>
                <p className="pricing10-text10 thq-body-large">8K Credits</p>
              </div>
            </div>
            <a
              href="https://app.coldhooks.com"
              className="pricing10-button2 thq-button-animated thq-button-filled"
            >
              <span className="pricing10-text11 thq-body-small">
                Start Trial
              </span>
            </a>
          </div>
          <div className="pricing10-column31">
            <div className="pricing10-price06">
              <div className="pricing10-price07">
                <p className="pricing10-text12 thq-body-large">Pro</p>
                <h3 className="pricing10-text13 thq-heading-3">$66</h3>
                <p className="pricing10-text14 thq-body-large">15K Credits</p>
              </div>
            </div>
            <a
              href="https://app.coldhooks.com"
              target="_blank"
              rel="noreferrer noopener"
              className="pricing10-button3 thq-button-animated thq-button-filled"
            >
              <span className="pricing10-text15 thq-body-small">
                Start Trial
              </span>
            </a>
          </div>
          <div className="pricing10-column32">
            <div className="pricing10-price08">
              <div className="pricing10-price09">
                <p className="pricing10-text16 thq-body-large">Agency</p>
                <h3 className="pricing10-text17 thq-heading-3">$126</h3>
                <p className="pricing10-text18 thq-body-large">30K Credits</p>
              </div>
            </div>
            <a
              href="https://app.coldhooks.com"
              target="_blank"
              rel="noreferrer noopener"
              className="pricing10-button4 thq-button-animated thq-button-filled"
            >
              <span className="pricing10-text19 thq-body-small">
                Start Trial
              </span>
            </a>
          </div>
          <div className="pricing10-column33">
            <div className="pricing10-price10">
              <div className="pricing10-price11">
                <p className="pricing10-text20 thq-body-large">Enterprise</p>
                <h3 className="pricing10-text21 thq-heading-3">$246</h3>
                <p className="pricing10-text22 thq-body-large">60K Credits</p>
              </div>
            </div>
            <a
              href="https://app.coldhooks.com"
              target="_blank"
              rel="noreferrer noopener"
              className="pricing10-button5 thq-button-animated thq-button-filled"
            >
              <span className="pricing10-text23 thq-body-small">
                Start Trial
              </span>
            </a>
          </div>
        </div>
        <div className="pricing10-container01">
          <div className="pricing10-content2">
            <h3 className="pricing10-text24 thq-heading-3">
              Price per 1K Credits
            </h3>
          </div>
          <div className="pricing10-container02">
            <div className="pricing10-container03"></div>
            <div className="pricing10-container04">
              <span className="pricing10-text25 thq-body-small">$6</span>
            </div>
            <div className="pricing10-container05">
              <span className="pricing10-text26 thq-body-small">$5</span>
            </div>
            <div className="pricing10-container06">
              <span className="pricing10-text27 thq-body-small">$4.5</span>
            </div>
            <div className="pricing10-container07">
              <span className="pricing10-text28 thq-body-small">$4.4</span>
            </div>
            <div className="pricing10-container08">
              <span className="pricing10-text29 thq-body-small">$4.2</span>
            </div>
            <div className="pricing10-container09">
              <span className="pricing10-text30 thq-body-small">$4</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pricing10.defaultProps = {
  rootClassName: '',
}

Pricing10.propTypes = {
  rootClassName: PropTypes.string,
}

export default Pricing10
