import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import Branding from './branding'
import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer">
      <div className="footer-information">
        <div className="footer-content">
          <div className="footer-header">
            <Link to="/" className="footer-navlink">
              <Branding className="footer-component"></Branding>
            </Link>
          </div>
          <div className="footer-contact-list">
            <div className="footer-contact">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="footer-image"
              />
              <span className="footer-text">contact@coldhooks.com | +91 8886101414</span>
            </div>
            <div className="footer-contact1">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="footer-image1"
              />
              <span className="footer-text01">{props.text22}</span>
            </div>
          </div>
        </div>
        <a
          href="https://algorythum.com"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-copyright"
        >
          <span>©2024 Algorythum International P</span>
          <span>rivate L</span>
          <span>imited</span>
          <span>. All Rights Reserved.</span>
        </a>
      </div>
      <div className="footer-links">
        <div className="footer-container">
          <span className="footer-text06 link">Blog</span>
          <Link to="/privacy-policy" className="footer-text07 link">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="footer-text08 link">
            Terms of Service
          </Link>
        </div>
        <div className="footer-container1">
          <span className="footer-text09 link">Blog</span>
          <Link to="/privacy-policy" className="footer-text10 link">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="footer-text11 link">
            Terms of Service
          </Link>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  imageAlt: 'image',
  imageSrc: '/group%201639.svg',
  text22: '+0 222.345.987',
  imageAlt1: 'image',
  imageSrc1: '/group%201640.svg',
}

Footer.propTypes = {
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  text22: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc1: PropTypes.string,
}

export default Footer
