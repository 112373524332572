import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features23.css'

const Features23 = (props) => {
  return (
    <div className="features23-layout349 thq-section-padding">
      <div className="features23-max-width thq-section-max-width">
        <div className="features23-container">
          <h2 className="features23-text">Discover</h2>
        </div>
        <div className="thq-grid-3">
          <div className="features23-container2 thq-card">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="features23-image thq-img-round"
            />
            <h2 className="features23-text01 thq-heading-2">Sample Hooks</h2>
            <span className="features23-text02 thq-body-small">
              Checkout the samples email intros generated by ColdHooks AI in the
              link below:
            </span>
            <a
              href="https://docs.google.com/spreadsheets/d/1T0xbToqx6nGjHhamZZjpg8EuAC5HEpQiTU7_nWwKBHQ/edit?usp=sharing"
              target="_blank"
              rel="noreferrer noopener"
              className="features23-button thq-button-animated thq-button-filled"
            >
              <span className="features23-text03 thq-body-small">
                <span>Checkout Samples</span>
                <br></br>
              </span>
            </a>
          </div>
          <div className="features23-container3 thq-card">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="features23-image1 thq-img-round"
            />
            <h2 className="features23-text06 thq-heading-2">Advanced Hooks</h2>
            <span className="features23-text07 thq-body-small">
              Checkout how to bulk feed prospects&apos; data and customize for
              desired tone &amp; context:
            </span>
            <a
              href="https://docs.coldhooks.com/coldhooking/advanced-customization"
              target="_blank"
              rel="noreferrer noopener"
              className="features23-button1 thq-button-animated thq-button-filled"
            >
              <span>
                {props.text21 ?? (
                  <Fragment>
                    <span className="features23-text14 thq-body-small">
                      <span>Advanced Customization</span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </span>
            </a>
          </div>
          <div className="features23-container4 thq-card">
            <img
              alt="image"
              src="/icon-default-fishing.svg"
              className="features23-image2 thq-img-round"
            />
            <h2 className="features23-text09 thq-heading-2">Default Hooks</h2>
            <span className="features23-text10 thq-body-small">
              Checkout the easy-way to generate 1000s of hooks in minutes using
              the Default Prompt:
            </span>
            <a
              href="https://docs.coldhooks.com/coldhooking/the-easy-way"
              target="_blank"
              rel="noreferrer noopener"
              className="features23-button2 thq-button-animated thq-button-filled"
            >
              <span className="features23-text11 thq-body-small">
                <span>The Easy Way</span>
                <br></br>
              </span>
            </a>
          </div>
        </div>
        <img
          alt="image"
          sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
          src="/screenshot-homepage-1400w.webp"
          srcSet="/screenshot-homepage-1400w.webp 1200w, /screenshot-homepage-tablet.webp 800w, /screenshot-homepage-mobile.webp 480w"
          className="features23-image3"
        />
      </div>
    </div>
  )
}

Features23.defaultProps = {
  feature1ImageAlt: 'image',
  feature1ImageSrc: '/icon-like.svg',
  text21: undefined,
  feature2ImageAlt: 'image',
  feature2ImageSrc: '/icon-advanced-fishing.svg',
}

Features23.propTypes = {
  feature1ImageAlt: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  text21: PropTypes.element,
  feature2ImageAlt: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
}

export default Features23
